@import './main.scss';
@import './variables.scss';

body {
	.app-container.private {
		min-height: 100vh;
		position: relative;
		padding-left: $menu-width;
		padding-top: $header-height;
		padding-bottom: $footer-height;
		transition: padding 0.2s linear;

		a,
		.fake-link {
			color: $color-tawny;
			text-decoration: underline;

			&:hover {
				color: $color-rufous;
			}

			&.btn {
				color: white;
				text-decoration: none;
			}
			&.no-decoration {
				text-decoration: none;
			}
		}
		.border {
			border: 1px solid $border-color !important;
		}
		.border-bottom {
			border-bottom: 1px solid $border-color !important;
		}

		.page-logo {
			margin-top: 5px;
			margin-left: 1px;
			display: block;
			float: left;

			img {
				width: 60px;
			}
		}

		.app-subtitle-divider {
			display: block;
			float: left;
			width: 1px;
			height: 1.5rem;
			background-color: rgba(0, 0, 0, 0.6);
			margin: 15px 20px 0;
		}

		.app-subtitle {
			text-transform: uppercase;
			font-weight: bold;
			margin-top: 19px;
			float: left;
		}

		.app-header {
			height: $header-height;
			width: calc(100% - #{$menu-width} - 60px);
			position: fixed;
			top: 0;
			right: 30px;
			background-color: white;
			transition: width 0.2s linear;
			z-index: 9;

			.app-header-container {
				display: flex;
				justify-content: space-between;
			}

			.global-settings-icon {
				font-size: 2rem;
				color: $color-rufous;
				margin-top: 10px;
				cursor: pointer;
			}
		}

		.app-breadcrumb {
			margin-left: 15px;
			margin-top: 12px;
			overflow: hidden;

			ul li {
				text-transform: uppercase;
				font-weight: bold;
				float: left;
				font-size: 0.75rem;
				.fa {
					margin-left: 10px;
					margin-right: 10px;
				}
			}
		}

		.app-navigation {
			width: $menu-width;
			height: 100vh;
			position: fixed;
			left: 0;
			top: 0;
			background-color: $color-rufous;
			color: white;
			padding-bottom: 15px;
			overflow-y: auto;
			overflow-x: hidden;
			transition: margin 0.2s linear;

			ul li {
				// border-bottom: $menu-link-border;
				a {
					color: white;
					display: block;
					padding: 5px 12px 5px 0;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
						// background-color: rgba(255, 255, 255, 0.3);
						// transition: background 0.1s linear;
					}

					&.active span {
						// background-color: $active-link-background;
						font-weight: bold;
						pointer-events: none;
					}
				}
			}

			.user-nolink {
				height: 40px;
				padding: 20px 15px 12px;
				font-size: 0.82rem;
				span {
					font-weight: bold;
				}
				// border-bottom: $menu-link-border;
			}

			.page-menu-toggle {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid $border-color;
				position: fixed;
				top: 65px;
				left: $menu-width;
				background-color: white;
				transform: translateX(-50%);
				color: $border-color;
				text-align: center;
				cursor: pointer;

				.fa {
					pointer-events: none;
				}

				&:hover {
					background-color: #f2f2f2;
				}
			}
		}

		.app-body {
			.card-wrapper {
				padding: 15px;
				background-color: #f7f7f7;
				z-index: 1;

				& > .card {
					border: 1px solid $border-color;
					border-radius: 0;
					// pointer-events: none;
					box-shadow: none;
					padding: 0;

					& > .card-body {
						text-align: left;
					}
				}
			}

			.tabs-links {
				margin-top: -35px;
				margin-bottom: 10px;
				z-index: 0;
				span {
					cursor: pointer;
					&.active {
						font-weight: bold;
					}

					&.disabled {
						cursor: default;
						pointer-events: none;
					}
					&:hover {
						color: $color-rufous;
					}
				}
			}
		}

		.app-footer {
			height: $footer-height;
			position: absolute;
			bottom: 0;
			right: 0;
			width: calc(100% - #{$menu-width} + 30px);
			font-size: 0.8rem;
			background-color: #f0f3f5;
			border-top: 1px solid #c2cfd6;
			margin-right: -15px;
			padding: 6px 0 0 30px;
			transition: width 0.2s linear;
		}
	}

	&.page-menu-hidden {
		.app-container.private {
			padding-left: 20px;

			.app-header {
				width: calc(100% - 20px - 60px);
			}

			.app-footer {
				width: calc(100% - 20px + 30px);
			}

			.app-navigation {
				margin-left: calc(#{-$menu-width} + 20px);
				cursor: pointer;

				* {
					display: none;
				}
			}
		}
	}

	.api-viewer-content-table {
		#tableOfContent {
			transition: top 0.3s ease;
		}
	}

	.api-viewer-single-header-container {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 14px;
			font-weight: bold;
			background-color: #f5f5f5;
			margin-bottom: 0;
			padding-bottom: 5px;
			padding-top: 5px;
			padding-left: 5px;
			transform: translateX(-5px);
		}
	}

	.api-viewer-content-content {
		position: relative;
		height: calc(100vh - 200px);
		overflow-y: auto;

		table {
			width: 100%;

			thead tr th,
			thead tr td,
			tbody tr td,
			tbody tr th {
				vertical-align: top !important;
				border: 1px solid black;
				padding: 5px;
			}
		}

		.kothing-editor-editable * {
			font-family: 'Montserrat', sans-serif !important;
		}

		.api-viewer-single-paragraph-container {
			position: relative;

			.api-viewer-single-paragraph {
				padding: 5px;
				border: 1px solid white;
				position: relative;

				&.active {
					border: 1px solid #bd2e2a;
				}

				&.selected {
					border: 1px solid #bd2e2a;
					background-color: rgba(189, 46, 42, 0.3);
				}
			}

			.api-viewer-single-paragraph-buttons-container {
				position: absolute;
				top: 0;
				left: 0;
				margin-top: -35px;

				.api-viewer-paragraph-toolbar {
					padding: 5px 5px 0px;
					background-color: white;
					border: 1px solid #ccc;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
					display: none;
					overflow: hidden;

					.api-viewer-paragraph-toolbar-button {
						width: 26px;
						height: 26px;
						border: 1px solid #777;
						border-radius: 2px;
						cursor: pointer;
						text-align: center;
						font-size: 1rem;
						float: left;
						margin-right: 5px;
						margin-bottom: 5px;
					}
				}
			}

			&:hover,
			&.visible {
				.api-viewer-single-paragraph {
					border: 1px dashed #2c5c65;
					cursor: default;
				}

				.api-viewer-paragraph-toolbar {
					display: block;
				}
			}

			&.active {
				.api-viewer-paragraph-toolbar {
					display: block;
				}

				.api-viewer-paragraph-toolbar-button.save {
					background-color: #bd2e2a;
					border-color: #bd2e2a;
					color: white;
				}
			}
			&.selected {
				.api-viewer-single-paragraph {
					border: 1px solid #bd2e2a;
					background-color: rgba(189, 46, 42, 0.3);
				}

				.api-viewer-paragraph-toolbar {
					display: block;
				}

				.api-viewer-paragraph-toolbar-button.select {
					background-color: #bd2e2a;
					border-color: #bd2e2a;
					color: white;
				}
			}
		}

		.api-viewer-single-header-container,
		.api-viewer-table-container,
		.api-viewer-list-item-container {
			position: relative;

			.api-viewer-single-header,
			.api-viewer-table,
			.api-viewer-list-item {
				padding: 5px;
				border: 1px solid white;
				position: relative;
			}

			.api-viewer-single-header-buttons-container,
			.api-viewer-table-buttons-container,
			.api-viewer-list-item-buttons-container {
				position: absolute;
				top: 0;
				left: 0;
				margin-top: -35px;

				.api-viewer-header-toolbar-button,
				.api-viewer-table-toolbar-button,
				.api-viewer-list-item-toolbar-button {
					width: 26px;
					height: 26px;
					border: 1px solid #777;
					border-radius: 2px;
					cursor: pointer;
					text-align: center;
					font-size: 1rem;
					float: left;
					margin-right: 5px;
					margin-bottom: 5px;
				}

				.api-viewer-header-toolbar,
				.api-viewer-table-toolbar,
				.api-viewer-list-item-toolbar {
					display: none;
					overflow: hidden;
					padding: 5px 5px 0;
					background-color: white;
					border: 1px solid #ccc;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
				}
			}

			&:hover,
			&.visible {
				.api-viewer-single-header,
				.api-viewer-table,
				.api-viewer-list-item {
					border: 1px dashed white;
					cursor: default;
				}

				// .api-viewer-header-toolbar,
				// .api-viewer-table-toolbar,
				// .api-viewer-list-item-toolbar {
				// 	display: block;
				// }
			}

			&.active {
				.api-viewer-header-toolbar,
				.api-viewer-table-toolbar,
				.api-viewer-list-item-toolbar {
					display: block;
				}

				.api-viewer-header-toolbar-button.save,
				.api-viewer-table-toolbar-button.save,
				.api-viewer-list-item-toolbar-button.save {
					background-color: #bd2e2a;
					border-color: #bd2e2a;
					color: white;
				}
			}
			&.deleted {
				.api-viewer-single-header {
					text-decoration: line-through;
				}
			}
		}

		.api-viewer-table-container {
			&.selected {
				.api-viewer-table {
					border: 1px solid #bd2e2a;
					background-color: rgba(189, 46, 42, 0.3);
				}

				.api-viewer-table-toolbar {
					display: block;
				}

				.api-viewer-table-toolbar-button.select {
					background-color: #bd2e2a;
					border-color: #bd2e2a;
					color: white;
				}
			}
		}

		.api-viewer-editor-toolbar {
			position: fixed;
			top: 70px;
			right: 32px;
			padding: 10px 15px;
			background-color: white;
			border: 1px solid #ccc;
			overflow: hidden;

			.api-viewer-toolbar-button {
				float: left;
				padding: 5px;
				font-size: 1.1rem;
				border: 1px solid #ccc;
				color: #777;
				border-radius: 2px;
				margin-right: 10px;
				width: 40px;
				height: 40px;
				text-align: center;
				cursor: pointer;

				&:hover {
					border-color: black;
					color: black;
				}
			}
		}
	}

	.api-viewer-single-header-container:hover {
		& > .api-viewer-single-header {
			border: 1px dashed #2c5c65 !important;
		}
		& > .api-viewer-single-header-buttons-container {
			& > .api-viewer-header-toolbar {
				display: block !important;
			}
		}
	}
	.api-viewer-table-container:hover {
		& > .api-viewer-table {
			border: 1px dashed #2c5c65 !important;
		}
		& > .api-viewer-table-buttons-container {
			& > .api-viewer-table-toolbar {
				display: block !important;
			}
		}
	}
	.api-viewer-list-item-container:hover {
		& > .api-viewer-list-item {
			border: 1px dashed #2c5c65 !important;
		}
		& > .api-viewer-list-item-buttons-container {
			& > .api-viewer-list-item-toolbar {
				display: block !important;
			}
		}
	}

	.api-viewer-table-container table td,
	.api-viewer-table-container table th {
		.api-viewer-single-paragraph-container {
			padding-right: 0;

			.api-viewer-single-paragraph {
				border-color: white !important;
			}
		}
		.api-viewer-single-paragraph-buttons-container {
			display: none;
		}
	}

	.taxonomy-mapping-tree-component {
		padding: 15px;
		border: 1px solid #ccc;

		.taxonomy-mapping-tree-title {
			font-weight: bold;
			margin-bottom: 15px;
			text-align: center;
			font-size: 1rem;
		}

		.tree-branch-children {
			color: #c00;
			margin-left: 20px;
		}

		ul {
			display: none;
			margin-left: 10px;
		}

		& > ul {
			display: block;
			margin-left: 0;
		}

		ul li {
			padding: 3px 5px 5px 15px;
			position: relative;

			span {
				font-weight: bold;

				&.mapped {
					font-weight: normal;
					color: #777;
				}
			}

			.tree-branch-checkbox-container.mapped {
				color: #bbb;
			}

			span.current {
				.tree-branch-checkbox {
					color: #b4392e;
					border-color: #b4392e;
				}
			}

			&.active {
				& > ul {
					display: block;
				}

				& > .fa-caret-right {
					transform: rotate(90deg);
				}
			}

			.fa-caret-right {
				position: absolute;
				top: 5px;
				left: 0;
				font-size: 18px;
				cursor: pointer;
			}

			.tree-branch-checkbox {
				width: 18px;
				height: 18px;
				border: 1px solid #999;
				border-radius: 2px;
				float: left;
				margin-top: 2px;
				margin-right: 5px;
				position: relative;

				.fa {
					position: absolute;
					line-height: 1;
					top: 1px;
					left: 1px;
					display: none;
				}
			}

			input[type='checkbox'] {
				display: none;

				&:checked + .tree-branch-checkbox .fa {
					display: block;
				}
			}
		}
	}

	.taxonomy-mapping-grid-component {
		table thead tr th {
			border-bottom: none;
		}

		.taxonomy-grid-mapped-taxonomies {
			float: left;
			color: #c00;
		}

		.taxonomy-grid-name-container {
			position: relative;
			padding-left: 13px;

			.fa {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 18px;
				margin-left: -10px;
				padding: 2px 10px;
				cursor: default;
				// transition-duration: 0.3s;
			}

			span {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		tr.not-active {
			.fa {
				transform: rotate(-90deg);
			}
		}

		.taxonomy-global-checkbox {
			width: 60px;

			label {
				width: 35px;
				height: 18px;
				border: 1px solid #999;
				border-radius: 9px;
				position: relative;
				margin: auto;
				cursor: pointer;

				.check-switch {
					display: block;
					width: 18px;
					height: 18px;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: -1px;
					background-color: #ddd;
					border: 1px solid #999;
					pointer-events: none;
				}
			}
			input[type='checkbox'] {
				display: none;

				&:checked + label .check-switch {
					left: auto;
					right: -1px;
					background-color: #bd2e2a;
				}
			}
		}
	}

	.taxonomy-mapping-popup {
		position: fixed;
		right: 0;
		top: 55px;
		bottom: 0;
		width: 600px;
		margin-right: -650px;
		background-color: white;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		transition: margin 0.3s ease-in-out;
		z-index: 999;

		.taxonomy-mapping-popup-close {
			position: fixed;
			top: 60px;
			right: 30px;
			font-size: 20px;
			cursor: pointer;
			line-height: 1;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease 0.3s;
		}

		&.active {
			margin-right: 0;

			.taxonomy-mapping-popup-close {
				opacity: 1;
				visibility: visible;
			}
		}

		.loadedContent {
			height: 100%;
		}

		.taxonomy-mapping-popup-container {
			height: calc(100% - 70px);
			overflow: auto;
			padding: 10px 15px;

			.taxonomy-mapping-popup-title {
				font-size: 1.4rem;
				margin-top: 25px;
				text-align: center;
			}
		}

		.taxonomy-mapping-popup-buttons {
			background-color: #f2f2f2;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 70px;
			padding: 15px 30px 15px 15px;
			border-top: 2px solid #ddd;
		}
	}

	.special-tag {
		padding: 5px;
		line-height: 1.3;
		display: inline-block;
		border-radius: 2px;

		&.regulatory_categories {
			border: 1px solid #ccc;
			background-color: $tagRCcolor;
		}
		&.service_lines {
			border: 1px solid #ccc;
			background-color: $tagSLcolor;
		}
		&.theme {
			border: 1px solid #ccc;
			background-color: $tagTHcolor;
		}
		&.clientTaxonomies {
			border: 1px solid #ccc;
			background-color: $tagCTcolor;
		}
	}
	.notification-preview-modal {
		.ant-modal-header {
			background-color: #f2f2f2;
			.ant-modal-title {
				font-weight: bold;
			}
		}
		.ant-modal-close svg path {
			fill: black;
		}
	}

	.editable-table-component {
		table tr td {
			position: relative;

			.fa-caret-up,
			.fa-caret-down {
				font-size: 1.2rem;
				position: absolute;
				top: 8px;
				left: 10px;
				cursor: pointer;
				line-height: 0.5;

				&.disabled {
					pointer-events: none;
					color: #aaa;
				}
			}
			.fa-caret-down {
				top: 23px;
			}
		}
	}
	.workflow-alert {
		border: 2px solid #c00;
		color: #c00;
		padding: 15px;
	}

	.menu-section {
		margin: 0 10px;

		.menu-section-title {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 12px;
			margin-bottom: 10px;
			opacity: 0.5;
		}

		hr {
			border-color: white;
			opacity: 0.5;
			margin: 12px 0;
		}
	}
	.ke-powered-by {
		display: none;
	}

	.v-align-top {
		table tbody tr td {
			vertical-align: top;
		}
	}
}
.table-smaller-text .ant-table table {
	font-size: 0.8rem;
}

.text-truncate {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.list-style-none {
	list-style: none;
	margin: 0;
	padding: 0;
}
.diff-content {
	del {
		background-color: rgb(255, 230, 230);
	}
	ins {
		background-color: rgb(230, 255, 230);
		text-decoration: none;
	}
}

.diff-button.active {
	background-color: #bd2e2a;
	color: white;
}

.combobox-dropdown-container {
	.a-input-size-sm {
		padding-right: 0 !important;
	}
	.a-input-text {
		padding-right: 30px;
		background-color: transparent !important;
	}
}

a.no-decoration {
	text-decoration: none;
}

.email-support-modal {
	max-width: 700px;

	a {
		color: $color-tawny;
	}
}

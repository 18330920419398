@import './variables.scss';

textarea.a-custom-textarea,
.react-datepicker__input-container input {
	border-radius: 0.125rem;
	padding: 0.625rem 0.625rem;
	display: flex;
	color: #2d2d2d;
	border: 0.0625rem solid #dedede;
	width: 100%;

	&:focus {
		box-shadow: 0 0 0 0.125rem #d04a02;
		outline: 0;
	}
}

.a-input-text {
	background-color: white;
}

.reg-ex-form-toggle {
	font-size: 24px;
	cursor: pointer;
	border-top: 1px solid #ccc;
	padding-top: 10px;
	margin-bottom: 20px;
}

.reg-ex-block-title {
	font-size: 24px;
	padding-bottom: 5px;
	margin-bottom: 20px;
	padding-top: 20px;
	border-bottom: 1px solid #ccc;
}

.a-input-text:disabled {
	background-color: #f7f7f7;
	border: 1px solid #dedede;
}

.show-summary {
	font-size: 10px;
	margin-left: 15px;
	display: inline-block;
	padding-right: 30px;
	position: relative;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.delete-icon,
.preferred-icon,
.special-icon {
	font-size: 20px;
	cursor: pointer;
	color: #7c858c;
	margin-top: 3px;

	&:hover {
		color: rgba(0, 0, 0, 1);
	}
}

.reg-ex-details-row .label {
	font-weight: bold;
}

.email-service-modal {
	max-width: 800px;
}

.date-tag {
	padding: 8px 14px;
	color: $color-tawny;
	border: 1px solid $color-tawny;
	white-space: nowrap;
	font-weight: bold;
	display: block;
}

.content_country {
	margin-right: 8px;
	font-size: 0.7rem;
	color: rgba(0, 0, 0, 0.5);
}

.table-reg-tag {
	font-weight: bold;
	color: $color-tawny;
}

.special-tag {
	font-size: 12px;
	font-weight: normal !important;
	padding: 3px 5px;
	margin-right: 4px;
	margin-bottom: 4px;
	display: inline-block;
	border-radius: 2px;
	border: 1px solid #ccc;
	line-height: 1.3;

	&.sl {
		background-color: $tag-sl-background;
		border-color: $tag-sl-border;
	}

	&.rc {
		background-color: $tag-rc-background;
		border-color: $tag-rc-border;
	}

	&.th {
		background-color: $tag-th-background;
		border-color: $tag-th-border;
	}

	&.control {
		background-color: $tag-control-background;
		border-color: $tag-control-border;
	}
}

.pwc-table {
	thead {
		tr {
			th {
				background-color: #e0e0e0;
				color: black;
				font-weight: 500;

				.fa {
					color: black;
					border-color: black;
				}
			}
		}
	}
}

.card {
	border-radius: 0;
}

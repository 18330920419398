@import '@style/variables.scss';

.taxonomy-map-selector {
	text-transform: uppercase;
	font-weight: 500;
	margin-top: 18px;
	display: flex;
	cursor: pointer;

	.taxonomy-map-selector-name {
		color: $color-rufous;
	}

	.fa {
		margin-top: 2px;
	}

	.taxonomy-map-selector-list {
		position: absolute;
		top: 100%;
		right: 0;
		background-color: white;
		box-shadow: 0 0.1875rem 0.4375rem 0.0625rem rgba(0, 0, 0, 0.16);

		.taxonomy-map-selector-list-item {
			padding: 10px 30px;
			font-weight: normal;
			border-bottom: 1px solid #dedede;

			&:last-child {
				border: none;
			}

			&:hover {
				background-color: #f4f4f4;
				cursor: pointer;
			}
		}
	}
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~font-awesome/scss/font-awesome.scss';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';
@import '~antd/dist/antd.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import './variables.scss';
@import './antd.scss';
@import './custom-components.scss';

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('ttf');
}

body {
	// font-family: 'Montserrat', sans-serif !important;
	font-family: 'PwC Helvetica Neue', Arial, Sans-Serif;
	font-size: 13px;

	.visually-hidden {
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}

	.mh-300 {
		min-height: 300px;
	}

	.page-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: $menu-width;
		background-color: rgba(255, 255, 255, 0.4);
		display: none;
		z-index: 1001;
	}

	&.page-menu-hidden {
		.page-overlay {
			left: 20px;
		}
	}

	.page-loader {
		position: fixed;
		left: calc(50% + (#{$menu-width} / 2));
		top: 50%;
		transform: translate(-50%, -50%);
		display: none;
		z-index: 1001;

		svg {
			fill: $color-primary !important;
		}
	}

	&.loading {
		.page-loader,
		.page-overlay {
			display: block;
		}
	}

	.btn {
		border-radius: 0;
		border: none;
		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: bold;

		&.btn-primary,
		&.btn-primary:active,
		&.btn-primary:focus {
			background-color: $color-primary !important;
		}

		&.btn-secondary,
		&.btn-secondary:active,
		&.btn-secondary:focus {
			background-color: $color-secondary !important;
		}

		&:hover:enabled {
			opacity: 0.8;
		}

		&:disabled {
			cursor: not-allowed;
		}
	}

	.list-style-none {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.special-icon {
		font-size: 1.2rem;
		cursor: pointer;
		color: #999;

		&:hover {
			color: black;
		}
	}

	input[type='text'].form-control,
	input[type='password'].form-control {
		border-color: $border-color;
		border-radius: 0;
	}

	&.overflow-hidden {
		overflow: hidden;
	}
}

// $color-primary: #bd2e2a;
$color-primary: #ad1b02;
$color-secondary: #888;
$border-color: #7c858c;
// $active-link-background: rgba(0, 0, 0, 0.4);
$active-link-background: #4999a9;
$menu-width: 215px;
$header-height: 55px;
$footer-height: 115px;
$menu-link-border: 1px solid rgba(255, 255, 255, 0.5);
$tagRCcolor: #f7b6d2;
$tagSLcolor: #98df8a;
$tagTHcolor: #b7b6f7;
$tagCTcolor: #cccccc;

// PWC colors
$color-black: #000000;
$color-rufous: #ad1b02;
$color-tawny: #d85604;
$color-beer: #e88d14;
$color-orange-yellow: #f3be26;
$color-china-pink: #e669a2;

$tag-sl-background: rgba(79, 153, 132, 0.2);
$tag-sl-border: rgba(79, 153, 132, 0.8);

$tag-rc-background: rgba(189, 46, 42, 0.2);
$tag-rc-border: rgba(189, 46, 42, 0.8);

$tag-th-background: rgba(183, 182, 247, 0.2);
$tag-th-border: rgba(183, 182, 247, 0.8);

$tag-ct-background: #ffe5f9;
$tag-ct-border: #e89dd7;

$tag-pr-background: #f9eba2;
$tag-pr-border: #c9ab00;

$tag-control-background: #a5d6a7;
$tag-control-border: #699b6b;

@import './variables.scss';

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: $border-color;
	border-radius: 0;
}
.ant-select-arrow {
	color: $border-color;
}
.ant-select-clear {
	top: 14px;
}

.ant-table-thead > tr > th {
	font-weight: bold;
}

.ant-btn {
	border-radius: 0;
	border: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: bold;
	color: white;
	background-color: $color-secondary !important;

	&.ant-btn-primary,
	&.ant-btn-primary:active,
	&.ant-btn-primary:focus {
		background-color: $color-primary !important;
	}

	&:hover {
		color: white;
		opacity: 0.8;
	}
}
.ant-picker {
	border-color: $border-color;
	border-radius: 0;
}

.ant-input {
	border-color: $border-color;
	border-radius: 0;
}

.ant-form-item-explain {
	font-size: 0.8rem;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $color-primary;
}
